import { useRef, useEffect, useState } from "react";

import TextTypingAnimation from "../components/TextTypingAnimation";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { Footer } from "../components/Footer";
import ParallaxText from "../components/ParallaxText";

import { useParallax, Parallax } from "react-scroll-parallax";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	return {
		width,
		height,
	};
}

// 주소
const 주소 = "서울 강남구 언주로 508 상록회관 5층";
const date1 = "2022년 11월 19일";
const date2 = "토요일 늦은 저녁";
const date3 = "06시 30분";

function FirstPage() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const parallax = useParallax({
		speed: -10,
	});

	return (
		<div
			className="App flex items-center justify-center mt-10"
			ref={parallax.ref}
		>
			<div
				className={`${
					windowDimensions.width > 800
						? "max-w-[800px]"
						: "max-w-[" + windowDimensions.width + "px]"
				} w-full pl-10`}
			>
				<div className="flex bg-primary flex-col dark:bg-[#282c34] ">
					<div className="h-screen">
						<div className="flex justify-center items-center h-[80vh]  py-3">
							<div
								className="h-full w-full py-3 px-2 shadow-md flex flex-col"
								style={{
									borderLeftWidth: 1,
									// borderTopWidth: 1,
									borderBottomWidth: 1,
									borderTopLeftRadius: 20,
									borderBottomLeftRadius: 20,
									boxShadow:
										"10px 20px 25px 10px rgb(0 0 0 / 0.1), 10px 8px 10px -6px rgb(0 0 0 / 0.1)",
								}}
							>
								<div
									className="flex  justfiy-center items-center flex-col"
									style={{ flex: 2 }}
								>
									<img
										className="flex rounded-xl"
										src="https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
										style={{ objectFit: "cover" }}
									></img>
									{/* <TextTypingAnimation
                                    className="block dark:text-white text-xl font-bold"
                                    texts={[
                                        "안녕하세요 반갑습니다. 하하하 호호호 헤헤헤. ",
                                    ]}
                                ></TextTypingAnimation>
                                <TextTypingAnimation
                                    className="block dark:text-white text-xl font-bold"
                                    texts={[
                                        "안녕하세요 반갑습니다. 하하하 호호호 헤헤헤. ",
                                    ]}
                                ></TextTypingAnimation>
                                <TextTypingAnimation
                                    className="block dark:text-white text-xl font-bold"
                                    texts={[
                                        "안녕하세요 반갑습니다. 하하하 호호호 헤헤헤. ",
                                    ]}
                                ></TextTypingAnimation> */}
								</div>
								<div
									className="flex flex-col items-end h-full py-2 mb-4"
									style={{
										flex: 1,
										justifyContent: "flex-end",
									}}
								>
									<div className="font-bold text-lg">
										{date1}
									</div>
									<div className="font-bold text-lg">
										{date2} {date3}
									</div>

									<div className="font-bold text-lg">
										상록아트홀 5층 / 아트홀
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col justify-center h-[20vh] relative">
							<ParallaxText baseVelocity={-5}>WORI</ParallaxText>
							<ParallaxText baseVelocity={5}>
								Kyelhon heayo
							</ParallaxText>
							{/* <Parallax speed={5}> */}
							{/* <div
                                className="background-dot absolute "
                                style={{ zIndex: 20 }}
                            ></div> */}
							{/* </Parallax> */}
						</div>
					</div>

					<Parallax className="h-full" speed={10}>
						<div className="bg-white" style={{ zIndex: 20 }}>
							{/* <div className="pt-20"></div> */}
							{/* <div className="font-bold">{date1}</div>
                            <div className="font-bold">
                                {date2} {date3}
                            </div>

                            <div>{주소}</div> */}
							<div className="flex justify-space items-center">
								<div className="flex flex-col">
									<div className="text-lg font-semibold">
										신랑에게 연락하기
									</div>
									<div className="flex">
										<div>
											<a href="tel:010-6433-0932">전화</a>
										</div>
										<div>
											<a href="sms:010-6433-0932">문자</a>
										</div>
									</div>
								</div>
								<div className="flex flex-col">
									<div className="text-lg font-semibold">
										신부에게 연락하기
									</div>
									<div className="flex">
										<div>
											<a href="tel:010-6433-0932">전화</a>
										</div>
										<div>
											<a href="sms:010-6433-0932">문자</a>
										</div>
									</div>
								</div>
							</div>
							<div>
								신랑측 혼주 아버지 : ㅇㅇㅇ 전화, 문자 어머지 :
								ㅇㅇㅇ 전화, 문자
							</div>
							<div>
								신부측 혼주 아버지 : ㅇㅇㅇ 전화, 문자 어머지 :
								ㅇㅇㅇ 전화, 문자
							</div>
							<div className="w-full">
								<div className="mt-5 pl-2">
									<p className="text-xl font-bold">
										오시는길
									</p>
								</div>

								<div className="w-full mt-3 ">
									<Map
										center={{
											lat: 37.50395,
											lng: 127.0428,
										}}
										style={{
											width: "100%",
											height: "360px",
										}}
										className="rounded-xl"
									>
										<MapMarker
											position={{
												lat: 37.50395,
												lng: 127.0428,
											}}
										></MapMarker>
									</Map>
								</div>
								<div></div>
							</div>
							<div className="">
								<div>신랑 & 신부에게 마음 전하기</div>
								<div>
									<div>신랑측</div>
									<div>신부측</div>
								</div>
								신랑 & 신부에게 마음 전하기 (축의금 ,계좌번호
								보기)
							</div>
						</div>
					</Parallax>
				</div>
			</div>
		</div>
	);
}

export default FirstPage;
