import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import Item from "./item";

const items = [
    {
        id: 1,
        name: "신랑측 계좌번호 확인",
        data: [
            {
                name: "모) 김태이",
                bank: {
                    name: "기업은행",
                    number: "01042256424",
                    copy: "01042256424",
                },
            },
            {
                name: "신랑 이강현",
                bank: {
                    name: "신한은행",
                    number: "110-396-616767",
                    copy: "110396616767",
                },
                kakaopay: {
                    link: "https://qr.kakaopay.com/FLuOmNdf9",
                },
            },
        ],
    },
    {
        id: 2,
        name: "신부측 계좌번호 확인",
        data: [
            {
                name: "부) 정용태",
                bank: {
                    name: "국민은행",
                    number: "445725-91-102818",
                    copy: "44572591102818",
                },
            },
            {
                name: "모) 송숙희",
                bank: {
                    name: "농협",
                    number: "221029-52-072570",
                    copy: "22102952072570",
                },
            },
            {
                name: "신부 정한솔",
                bank: {
                    name: "카카오뱅크",
                    number: "3333011141352",
                    copy: "3333011141352",
                },
                kakaopay: {
                    link: "https://qr.kakaopay.com/FWWX2fzue",
                },
            },
        ],
    },
    // {
    // 	id: 2,
    // 	name: "신부측 계좌번호 확인",
    // 	bank: {
    // 		name: "카카오뱅크",
    // 		number: "3333011141352",
    // 		copy: "3333011141352",
    // 	},
    // 	kakaopay: {
    // 		link: "https://qr.kakaopay.com/FWWX2fzue",
    // 	},
    // 	parent: [
    // 		{
    // 			name: "모)송숙희",
    // 			bank: {
    // 				name: "계좌번호",
    // 				number: "3333011141352",
    // 				copy: "3333011141352",
    // 			},
    // 		},
    // 	],
    // },
];
const Accordion = () => {
    return (
        <AnimateSharedLayout>
            <div className="w-60">
                <motion.ul
                    className="w-full"
                    layout
                    transition={{
                        duration: 0.3,
                        ease: [0.43, 0.13, 0.23, 0.96],
                    }}
                >
                    {items.map((item, index) => (
                        <Item key={item.id} item={item}></Item>
                    ))}
                </motion.ul>
            </div>
        </AnimateSharedLayout>
    );
};
export default Accordion;
