import { motion, AnimatePresence, layout } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
const Item = ({ item }) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<>
			<ItemWrap
				className="w-full bg-default dark:bg-darkDefault"
				onClick={toggleOpen}
				layout
				transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
				key={item}
			>
				<motion.h1 className="w-full text-center dark:border-b-[#888] flex justify-center items-center">
					{item.name}
					<ChevronDownIcon
						className={`ml-5 h-5 w-5 accordion-title ${
							isOpen ? "open" : ""
						}`}
					></ChevronDownIcon>
				</motion.h1>
			</ItemWrap>
			<AnimatePresence>
				{isOpen && (
					<SubWrap className="bg-default dark:bg-darkDefault cursor-pointer">
						{item.data.map((data, index) => {
							return (
								<>
									<motion.h6
										layout
										initial={{ y: -10, opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{
											duration: 0.3,
											ease: [0.43, 0.13, 0.23, 0.96],
										}}
										style={{
											paddingTop: 16,
										}}
									>
										<div>{data.name}</div>
									</motion.h6>
									<motion.h6
										layout
										initial={{ y: -10, opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{
											duration: 0.3,
											ease: [0.43, 0.13, 0.23, 0.96],
										}}
										style={{
											paddingTop: 16,
											paddingBottom: 16,
										}}
									>
										<CopyToClipboard
											text={data.bank.copy}
											onCopy={(text, result) => {
												if (result) {
													alert(
														"계좌번호가 복사되었습니다."
													);
												}
												// console.log(text, result);
											}}
										>
											<div>
												<div className="pb-1">
													계좌번호 [복사하기]
												</div>
												<div>
													{data.bank.name}{" "}
													{data.bank.number}
												</div>
											</div>
										</CopyToClipboard>
									</motion.h6>
									{data.kakaopay && (
										<motion.h6
											layout
											initial={{ y: -10, opacity: 0 }}
											animate={{ y: 0, opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{
												duration: 0.3,
												ease: [0.43, 0.13, 0.23, 0.96],
											}}
											style={{
												// paddingTop: 16,
												paddingBottom: 16,
											}}
										>
											<div
												className="cursor-pointer"
												onClick={() =>
													window.open(
														data.kakaopay.link
													)
												}
											>
												<div className="pb-1">
													카카오페이
												</div>
												<img
													src="/assets/images/kakaopay.png"
													className="h-14 w-14"
													alt="kakao"
												></img>
											</div>
										</motion.h6>
									)}
									{index < item.data.length - 1 && <hr />}
								</>
							);
						})}
					</SubWrap>
				)}
			</AnimatePresence>
		</>
	);
};
export default Item;

const ItemWrap = styled(motion.li)`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: -1px;
	background: white;
	overflow: hidden;

	h1 {
		padding: 22px 30px 21px 30px;

		z-index: 1;
	}
`;

const SubWrap = styled(motion.div)`
	font-size: 15px;
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: white;

	h5 {
		padding: 20px 30px;
		font-weight: 500;
		line-height: 150%;
		text-align: left;
	}

	:last-child {
		border-radius: 0 0 20px 20px;
	}
`;
