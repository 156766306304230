import "./App.css";
import FirstPage from "./pages/first";
import SecondPage from "./pages/second";
import SecondDarkPage from "./pages/second_dark";
import ThridPage from "./pages/thrid";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/1" element={<FirstPage></FirstPage>}></Route>
				<Route path="/2" element={<SecondPage></SecondPage>}></Route>
				<Route path="/3" element={<ThridPage></ThridPage>}></Route>
				<Route
					path="/2_1"
					element={<SecondDarkPage></SecondDarkPage>}
				></Route>
				<Route path="/" element={<SecondPage></SecondPage>}></Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
