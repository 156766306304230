import { useRef, useEffect, useState } from "react";
import Calendar from "../components/calendar/dist/esm";

import TextTypingAnimation from "../components/TextTypingAnimation";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { Footer } from "../components/Footer";

import { useScrollFadeIn } from "../hook";

import moment from "moment";
import { ChatBubbleLeftIcon, PhoneIcon } from "@heroicons/react/24/outline";

import Accordion from "../components/Accordion";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../components/calendar/dist/Calendar.css";
import "./thrid.css";
import SmoothScrollbar from "smooth-scrollbar";

const REACT_APP_URL = process.env.REACT_APP_URL || "http://localhost:3000";
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	return {
		width,
		height,
	};
}
const contents = [
	{
		title: "Danielle Vail",
		image: "https://picsum.photos/seed/photo1/1000",
	},
	{
		title: "Agatha Holborn",
		image: "https://picsum.photos/seed/photo2/1000",
	},
	{
		title: "Leonora-Lettie Tomson",
		image: "https://picsum.photos/seed/photo3/1000",
	},
	{
		title: "Taryn Rihanna Leary",
		image: "https://picsum.photos/seed/photo4/1000",
	},
	{
		title: "Krystin Elphinstone",
		image: "https://picsum.photos/seed/photo5/1000",
	},
	{
		title: "Jazlyn Hilton",
		image: "https://picsum.photos/seed/photo6/1000",
	},
	{
		title: "Alisa-Jewell Woods",
		image: "https://picsum.photos/seed/photo7/1000",
	},
	{
		title: "Ariel-Rayna Steel",
		image: "https://picsum.photos/seed/photo8/1000",
	},
	{
		title: "Sidney-Patricia Carwardine",
		image: "https://picsum.photos/seed/photo9/1000",
	},
	{
		title: "Dee Pickles",
		image: "https://picsum.photos/seed/photo10/1000",
	},
];

function ThridPage() {
	gsap.registerPlugin(ScrollTrigger);

	const [darkMode, setDarkMode] = useState(false);
	const [value, onChange] = useState(new Date("2022-11-19"));

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		const DARK = window.localStorage.getItem("LEEKH_DARK");

		if (DARK === "true") {
			setDarkMode(true);
		} else {
			setDarkMode(false);
			window.localStorage.setItem("LEEKH_DARK", false);
		}
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		if (!window.Kakao.isInitialized()) {
			window.Kakao.init("e0487e90cd6612dfe97a4f703dc8368d");
		}
		mounted();

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const mounted = () => {
		let bodyScrollBar = SmoothScrollbar.init(document.body, {
			damping: 0.1,
			delegateTo: document,
		});
		ScrollTrigger.scrollerProxy(".scroller", {
			scrollTop(value) {
				if (arguments.length) {
					bodyScrollBar.scrollTop = value;
				}
				return bodyScrollBar.scrollTop;
			},
		});
		bodyScrollBar.addListener(ScrollTrigger.update);

		gsap.set(".panel", {
			zIndex: (i, target, targets) => targets.length - i,
		});

		var images = gsap.utils.toArray(".panel:not(.purple)");

		images.forEach((image, i) => {
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: "section.black",
					scroller: ".scroller",
					start: () => "top -" + window.innerHeight * (i + 0.5),
					end: () => "+=" + window.innerHeight,
					scrub: true,
					toggleActions: "play none reverse none",
					invalidateOnRefresh: true,
				},
			});

			tl.to(image, { height: 0 });
		});

		gsap.set(".panel-text", {
			zIndex: (i, target, targets) => targets.length - i,
		});

		var texts = gsap.utils.toArray(".panel-text");

		texts.forEach((text, i) => {
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: "section.black",
					scroller: ".scroller",
					start: () => "top -" + window.innerHeight * i,
					end: () => "+=" + window.innerHeight,
					scrub: true,
					toggleActions: "play none reverse none",
					invalidateOnRefresh: true,
				},
			});

			tl.to(text, { duration: 0.33, opacity: 1, y: "50%" }).to(
				text,
				{ duration: 0.33, opacity: 0, y: "0%" },
				0.66
			);
		});

		ScrollTrigger.create({
			trigger: "section.black",
			scroller: ".scroller",
			scrub: true,
			markers: true,
			pin: true,
			start: () => "top top",
			end: () => "+=" + (images.length + 1) * window.innerHeight,
			invalidateOnRefresh: true,
		});
	};

	// const parallax = useParallax({
	// 	speed: -10,
	// });
	const width =
		windowDimensions.width > 800 ? "800px" : windowDimensions.width + "px";

	const shareKakao = () => {
		window.Kakao.Link.sendDefault({
			objectType: "feed",
			content: {
				title: "이강현 ❤️ 정한솔 결혼합니다.",
				description:
					"11월 19일 토요일 오후 6시 30분 \n상록아트홀 / 5층 아트홀",
				imageUrl: "https://wedding.leekh.dev/assets/images/photo.jpeg",
				imageWidth: 1200,
				imageHeight: 630,
				link: {
					mobileWebUrl: REACT_APP_URL,
					webUrl: REACT_APP_URL,
				},
			},
			buttons: [
				{
					title: "모바일 청첩장 보기",
					link: {
						mobileWebUrl: REACT_APP_URL,
						webUrl: REACT_APP_URL,
					},
				},
			],
		});
	};
	return (
		<div className="App">
			<div className="scroller">
				<section className="orange">
					<div className="text">
						This is some text inside of a div block.
					</div>
				</section>

				<section className="black">
					<div className="text-wrap">
						<div className="panel-text blue-text">Blue</div>
						<div className="panel-text red-text">Red</div>
						<div className="panel-text orange-text">Orange</div>
						<div className="panel-text purple-text">Purple</div>
					</div>

					<div className="p-wrap">
						<div className="panel blue"></div>
						<div className="panel red"></div>
						<div className="panel orange"></div>
						<div className="panel purple"></div>
					</div>
				</section>

				<section className="blue"></section>
			</div>
		</div>
	);
}

export default ThridPage;
