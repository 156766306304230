import { useRef, useEffect, useState } from "react";
import Calendar from "../components/calendar/dist/esm";

import TextTypingAnimation from "../components/TextTypingAnimation";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { Footer } from "../components/Footer";

import { useScrollFadeIn } from "../hook";

import moment from "moment";
import { ChatBubbleLeftIcon, PhoneIcon } from "@heroicons/react/24/outline";

import Accordion from "../components/Accordion";

import "../components/calendar/dist/Calendar.css";
import "./second.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	return {
		width,
		height,
	};
}

// 주소
const 주소 = "서울 강남구 언주로 508 상록회관 5층";
const date1 = "2022년 11월 19일";
const date2 = "토요일 늦은 저녁";
const date3 = "06시 30분";

function SecondPage() {
	const [value, onChange] = useState(new Date("2022-11-19"));
	const [isOpen, setIsOpen] = useState(false);

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		if (!window.Kakao.isInitialized()) {
			window.Kakao.init("e0487e90cd6612dfe97a4f703dc8368d");
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// const parallax = useParallax({
	// 	speed: -10,
	// });
	const width =
		windowDimensions.width > 800 ? "800px" : windowDimensions.width + "px";

	return (
		<div
			className="App flex items-center justify-center dark "
			style={{
				backgroundColor: "#1a1a1a",
			}}
		>
			<div
				className="dark:bg-[#222]"
				style={{
					width: width,
					maxWidth: width,
					overflowX: "hidden",
				}}
			>
				<div className="w-full pl-10 dark:text-white">
					<div className="h-screen pt-10 relative ">
						<div className="h-full flex flex-col justify-center items-center -mt-20">
							<div className="pr-10 flex justify-center items-center">
								<img
									style={{ borderRadius: "16px" }}
									alt=""
									src="/assets/images/photo.jpeg"
								></img>
							</div>
							<div className="flex justify-center pr-10 h-10">
								<TextTypingAnimation
									className="text-2xl pt-10 "
									texts={["이강현 그리고 정한솔 "]}
								></TextTypingAnimation>
							</div>

							<div className="absolute bottom-8 right-5 text-lg">
								<div>2022. 11. 19. SAT PM 06:30</div>
								<div className="text-sm flex justify-end">
									<span>상록아트홀</span> /{" "}
									<span className="pl-1">5층 아트홀</span>
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-center items-center w-full pt-[6rem] pb-[6rem] ">
						<hr className="flex w-[80%]"></hr>
					</div>

					<div className="flex flex-col items-end px-4">
						<div className="py-1 ">
							새로운 마음과 새 의미를 간직하며{" "}
						</div>
						<div className="py-1">
							저희 두 사람이 새 출발의 첫걸음을 내딛습니다.{" "}
						</div>
						<div className="py-1">
							좋은 꿈, 바른 뜻으로 올바르게 살 수 있도록
						</div>
						<div className="py-1">
							축복과 격려 주시면 더 없는 기쁨으로
						</div>
						<div className="py-1">간직하겠습니다.</div>
						<div
							{...useScrollFadeIn("left", 0.8, 0)}
							className="pt-10 py-1 w-full flex justify-end"
						>
							<span className="w-[170px] pr-2">
								이기봉 ・ 김태이의 아들
							</span>
							<span className="font-bold">강현</span>
						</div>
						<div
							{...useScrollFadeIn("right", 0.8, 0)}
							className="py-1 w-full flex justify-end"
						>
							<span className="w-[170px] pr-2">
								정용태 ・ 송숙희의 &nbsp;딸
							</span>
							<span className="font-bold">한솔</span>
						</div>
					</div>

					<div className="flex justify-center items-center w-full pt-[6rem] pb-[3rem]">
						<hr className="flex w-[80%]"></hr>
					</div>
					<div className="pr-10 py-10 flex justify-center items-center ">
						<Calendar
							className="dark:bg-[#222]"
							calendarType="US"
							style={{ width: width }}
							value={value}
							formatDay={(locale, date) =>
								moment(date).format("DD")
							}
							showNeighboringMonth={false}
							minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
							maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
							navigationLabel={({
								date,
								label,
								locale,
								view,
							}) => {
								return (
									<div className="flex justify-between px-4 dark:bg-[#222] dark:text-white">
										<div>2022년 11월</div>
										<div>늦은오후 06:30</div>
									</div>
								);
							}}
						/>
					</div>
					<div className="flex justify-center items-center w-full pt-[3rem] pb-[3rem] ">
						<hr className="flex w-[80%]"></hr>
					</div>

					<div className="">
						<div className="flex justify-center items-center">
							<p className="p-2">신랑에게 연락하기</p>
							<div className="p-2">
								<a href="tel:010-6433-0932">
									<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
								</a>
							</div>
							<div className="p-2">
								<a href="sms:010-6433-0932">
									<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
								</a>
							</div>
						</div>
						<div className="flex justify-center items-center pt-6">
							<p className="p-2">신부에게 연락하기</p>
							<div className="p-2">
								<a href="tel:010-4190-2801">
									<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
								</a>
							</div>
							<div className="p-2">
								<a href="sms:010-4190-2801">
									<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
								</a>
							</div>
						</div>
						<div className="pt-16 flex justify-around pr-10">
							<div>
								<div className="font-semibold">신랑측 혼주</div>
								<div className="pt-2">
									<div className="flex">
										<div className="text-sm flex justify-end items-end pr-1">
											<span>아버지</span>
										</div>
										<span>이기봉</span>
									</div>
									<div className="flex">
										<div className="p-2">
											<a href="tel:010-9421-1117">
												<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
											</a>
										</div>
										<div className="p-2">
											<a href="sms:010-9421-1117">
												<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
											</a>
										</div>
									</div>

									<div className="flex pt-4">
										<div className="text-sm flex justify-end items-end pr-1">
											<span>어머니</span>
										</div>
										<span>김태이</span>
									</div>
									<div className="flex">
										<div className="p-2">
											<a href="tel:010-4225-6424">
												<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
											</a>
										</div>
										<div className="p-2">
											<a href="sms:010-4225-6424">
												<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div className="font-semibold">신부측 혼주</div>
								<div className="pt-2">
									<div className="flex">
										<div className="text-sm flex justify-end items-end pr-1">
											<span>아버지</span>
										</div>
										<span>정용태</span>
									</div>
									<div className="flex">
										<div className="p-2">
											<a href="tel:010-9421-1117">
												<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
											</a>
										</div>
										<div className="p-2">
											<a href="sms:010-9421-1117">
												<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
											</a>
										</div>
									</div>

									<div className="flex pt-4">
										<div className="text-sm flex justify-end items-end pr-1">
											<span>어머니</span>
										</div>
										<span>송숙희</span>
									</div>
									<div className="flex">
										<div className="p-2">
											<a href="tel:010-4225-6424">
												<PhoneIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></PhoneIcon>
											</a>
										</div>
										<div className="p-2">
											<a href="sms:010-4225-6424">
												<ChatBubbleLeftIcon className="h-6 w-6 dark:text-[#eee] text-[#333]"></ChatBubbleLeftIcon>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-center items-center w-full pt-[3rem] pb-[3rem] ">
						<hr className="flex w-[80%]"></hr>
					</div>
					<div className="my-10">
						<p
							className="font-bold text-xl"
							{...useScrollFadeIn("right", 0.8, 0)}
						>
							오시는길
						</p>
						<div
							className="pt-4"
							{...useScrollFadeIn("left", 0.8, 0, 0.1)}
						>
							<Map
								center={{
									lat: 37.50395,
									lng: 127.0428,
								}}
								style={{
									width: "100%",
									height: "360px",
								}}
								className="rounded-xl"
							>
								<MapMarker
									position={{
										lat: 37.50395,
										lng: 127.0428,
									}}
								></MapMarker>
							</Map>
						</div>
						<div className="pt-10">
							<p className="font-semibold">
								상록아트홀 / 5층 아트홀
							</p>
							<p>서울특별시 강남구 언주로 508</p>
							<p>02-564-5757</p>
						</div>
						<div className="pt-10">
							<p
								className="font-semibold"
								{...useScrollFadeIn("right", 0.8, 0)}
							>
								🚃 지하철
							</p>
							<p className="pt-2 pl-2">
								2호선/분당선 "선릉역" 5번출구 도보 5분
							</p>
							<p className="pl-2">
								▶︎ 셔틀버스 운행(수시운행) : 선릉역 5번출구
							</p>
						</div>
						<div className="pt-10">
							<p
								className="font-semibold"
								{...useScrollFadeIn("right", 0.8, 0)}
							>
								🚍 버스
							</p>
							<div className="pt-2 pl-2">
								<p className="font-semibold">
									KT 강남지사 하차
								</p>
								<p className="pt-1">간선버스(파랑) : 141</p>
								<p className="pt-1">간선버스(초록) : 3422</p>
							</div>
							<div className="pt-4 pl-2">
								<p className="font-semibold">
									한국기술센터, 상록회관 하차
								</p>
								<p className="pt-1">간선버스(파랑) : 146</p>
								<div className="pt-1">
									<div className="flex">
										간선버스(파랑) : 360
									</div>
								</div>
								<p className="pt-1">간선버스(파랑) : 740</p>
							</div>
						</div>
						<div className="pt-10">
							<div
								className="font-semibold"
								{...useScrollFadeIn("right", 0.8, 0)}
							>
								🚗 자가용
							</div>
							<div className="flex pt-8 justify-around pr-10">
								<div
									className="h-16 w-16 flex justify-center items-center object-cover rounded-2xl mx-1 flex-col"
									onClick={() => {
										window.Kakao.Navi.start({
											name: "상록아트홀",
											x: 127.0429552,
											y: 37.5038913,
											coordType: "wgs84",
										});
									}}
								>
									<img
										className="w-full h-full rounded-md"
										src="https://developers.kakao.com/assets/img/about/buttons/navi/kakaonavi_btn_medium.png"
										alt="길 안내하기 버튼"
										// style={{
										// 	boxShadow:
										// 		"0 12px 36px rgba(0, 0, 0, 0.1)",
										// }}
									/>
									<div className="pt-2">카톡네비</div>
								</div>
								{/* <div>네이버지도</div> */}
								<div
									className="h-16 w-16 flex justify-center items-center rounded-xl mx-1 flex-col"
									onClick={() => {
										window.open(
											"https://apis.openapi.sk.com/tmap/app/routes?appKey=l7xxc3f305e9cd524b68a3c1593b562defe9&name=상록아트홀&lon=127.0429552&lat=37.5038913"
										);
									}}
								>
									<img
										className="w-full h-full rounded-md"
										src="/assets/images/tmap.jpeg"
										alt="tmap"
										style={{
											boxShadow:
												"0 12px 36px rgba(0, 0, 0, 0.1)",
										}}
									></img>
									<div className="pt-2">T맵</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex justify-center items-center w-full pt-[3rem] pb-[3rem] ">
						<hr className="flex w-[80%]"></hr>
					</div>
					<div className="">
						<div
							{...useScrollFadeIn("up", 0.8, 0, 0.8)}
							className="text-lg font-semibold"
						>
							신랑 & 신부에게 마음 전하기
						</div>
						<div className="text-sm">
							축복의 의미로 축의금을 전달해 보세요.
						</div>
						<div className="flex justify-around pt-6 flex-col">
							<div className="flex justify-center pr-10 items-center w-full flex-col">
								<Accordion></Accordion>
							</div>
						</div>
					</div>
					<div className="flex justify-center items-center w-full pt-[3rem] pb-[3rem] ">
						<hr className="flex w-[80%]"></hr>
					</div>
					<div className="text-sm w-full pb-10 pt-5 pr-10 text-center text-[#333] dark:text-[#eee]">
						Copyright 2022. 이강현 All rights reserved.
					</div>
				</div>
			</div>
		</div>
	);
}

export default SecondPage;
